$(function(){
  //menu
  $("#g-nav-btn").click(function () {
    $(this).toggleClass("active");
    $("#header").toggleClass("active");
    $("#g-nav").toggleClass("active");
    $("#g-nav-bg").toggleClass("active");
  });
  $("#g-nav a").on("click", function () {
		console.log("btn");
		$("#g-nav").removeClass("active");
    $("#header").removeClass("active");
		$("#g-nav-btn").removeClass("active");
    $("#g-nav-bg").removeClass("active");
	});

  //スムーズスクロール
  $('a[href^="#"]').click(function () {
    var adjust = 0;
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top + adjust;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  var fix    = $(".article__mv__head"); 
  var fixTop = fix.offset().top;
  $(window).scroll(function () { 
    if($(window).scrollTop() >= fixTop) {
      fix.addClass("fixed");
    } else {
      fix.removeClass("fixed"); 
    }
    
  });
});